<template>
  <influencersEsqueciSenha v-if="isInfluencers()"></influencersEsqueciSenha>
  <ptEsqueciSenha v-else-if="isPt()"></ptEsqueciSenha>
  <nxlEsqueciSenha v-else-if="isNxl()"></nxlEsqueciSenha>
  <bellaentradaEsqueciSenha v-else-if="isBelaEntrada()" ></bellaentradaEsqueciSenha>
  <sbeventosEsqueciSenha v-else-if="isSBEventos()"></sbeventosEsqueciSenha>
  <elfortEsqueciSenha v-else-if="isElfor()"></elfortEsqueciSenha>
  <porterEsqueciSenha v-else-if="isPorter()"></porterEsqueciSenha>
  <ingressoLivreEsqueciSenha v-else-if="isIngressoLivre()" ></ingressoLivreEsqueciSenha> 
  <milgrauEsqueciSenha v-else></milgrauEsqueciSenha>
</template>

<script>
import ingressoLivreEsqueciSenha from "./ingressoLivre/esqueciSenha.vue";
import influencersEsqueciSenha from "./influencers/esqueciSenha.vue";
import milgrauEsqueciSenha from "./milgrau/esqueciSenha.vue";
import bellaentradaEsqueciSenha from "./belaentrada/esqueciSenha.vue";
import sbeventosEsqueciSenha from "./sbeventos/esqueciSenha.vue";
import elfortEsqueciSenha from "./elfort/esqueciSenha.vue";
import porterEsqueciSenha from "./porter/esqueciSenha.vue";
import ptEsqueciSenha from "./pt/esqueciSenha.vue";
import nxlEsqueciSenha from "./nxl/esqueciSenha.vue";

export default {
  components: {
    influencersEsqueciSenha,
    bellaentradaEsqueciSenha,
    milgrauEsqueciSenha,
    ingressoLivreEsqueciSenha,
    sbeventosEsqueciSenha,
    elfortEsqueciSenha,
    porterEsqueciSenha,
    ptEsqueciSenha,
    nxlEsqueciSenha
  },
};
</script>
